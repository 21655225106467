module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-theme-elk/gatsby-browser.js'),
      options: {"plugins":[],"blogHelperOptions":{"enabledLike":true,"enabledVisitor":true,"apiUrl":"https://lxz03fie0k.execute-api.ap-northeast-1.amazonaws.com/prod"},"blogContentPath":"/opt/build/repo/content/blog-posts","assetContentPath":"/opt/build/repo/content/assets","pagesContentPath":"/opt/build/repo/content/pages","basePath":"/","author":"Alex Chueng","siteTitle":"Alex Chueng's blogs","description":"Bring yourself back online.","siteUrl":"https://thinkmore.xyz","social":{"mail":"zh.sanget@gmail.com","github":"https://github.com/SANGET"},"footer":{"showBuildInfo":true,"since":false},"sideMenu":[{"title":"Blog","path":"/"},{"title":"Archive","path":"/archive"},{"title":"Tags","path":"/tags"},{"title":"About","path":"/about"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-125030746-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sanget's Blog","short_name":"Sanget's blog","start_url":"/","background_color":"#ffffff","theme_color":"#376bfb","display":"minimal-ui","icon":"content/assets/profile-pic.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a9bea97746e2b7073a739b92a0423072"},
    }]
